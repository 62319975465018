import * as React from "react"
import { graphql, Link } from 'gatsby'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import Layout from "../components/layout"

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class NotFoundPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  render() { 
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }
    
    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <div className="basepagewrapper">
          <p>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for.
            <br />
            {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in <code>src/pages/</code>.
                <br />
              </>
            ) : null}
            <br />
            <Link to="/">Go home</Link>.
          </p>

          </div>
        </Layout>
      </React.Fragment>
      
    )
  }
}

export default withCookies(NotFoundPage)
export const NotFoundPageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl              
        }
        keywords
      }
    }
    sitePage {
        pluginCreator {
          name
        }
      }
  }
`